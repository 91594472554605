@font-face {
  font-family: "Apple SD Gothic neo";
  font-style: normal;
  font-weight: 100;
  src: url("./AppleSDGothicNeo-Thin.woff2") format("woff2");
}

@font-face {
  font-family: "Apple SD Gothic neo";
  font-style: normal;
  font-weight: 200;
  src: url("./AppleSDGothicNeo-ExtraLight.woff2") format("woff2");
}

@font-face {
  font-family: "Apple SD Gothic neo";
  font-style: normal;
  font-weight: 300;
  src: url("./AppleSDGothicNeo-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Apple SD Gothic neo";
  font-style: normal;
  font-weight: 400;
  src: url("./AppleSDGothicNeo-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Apple SD Gothic neo";
  font-style: normal;
  font-weight: 500;
  src: url("./AppleSDGothicNeo-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Apple SD Gothic neo";
  font-style: normal;
  font-weight: 600;
  src: url("./AppleSDGothicNeo-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: "Apple SD Gothic neo";
  font-style: normal;
  font-weight: 700;
  src: url("./AppleSDGothicNeo-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Apple SD Gothic neo";
  font-style: normal;
  font-weight: 900;
  src: url("./AppleSDGothicNeo-Black.woff2") format("woff2");
}
